import dayjs from '@/utils/dayjs.min.js'
const timeFormat = time => {
    // timeFormater(value, str = 'YYYY-MM-DD') {
        return dayjs(time).format('YYYY/MM/DD')
    // }
}

const addressFormat = address => {
    var addressList = address.split("/")
    var out = ""
    addressList.forEach(item => {
        out = out + " " + item
    })
    return out
}
const telFormat = tel => {
    tel = tel + ""
    var mphone = tel.substring(0, 3) + '****' + tel.substring(7);
    return mphone;
}
export {
    addressFormat,
    telFormat,
    timeFormat,
}