<template>
  <div class="foot w">
    <!-- <div class="foot__title">Aoyin</div>
    <div class="foot__nav">
      <ul class="button">
        <li
          v-for="(item, index) in navList"
          :key="index"
          :class="navIndex == index ? 'active' : ''"
          @click="changeIndex(index)"
          :data-index="index"
        >
          <router-link :to="item.url">{{ item.name }}</router-link>
        </li>
      </ul>
    </div>
    <div class="breakTop">
      <div class="breakTop__button">返回顶部</div>
    </div> -->
    <div class="content m">
      <div class="left">
        <!-- <ul>
          <li><a href="https://www.cnmumen.com/">网站首页</a></li>
          |
          <li><a href="https://www.cnmumen.com/index/door.html">频道分类</a></li>
          |
          <li><a href="https://www.cnmumen.com/about/index.html">关于我们</a></li>
          |
          <li><a href="https://www.cnmumen.com/about/contact.html">联系方式</a></li>
          |
          <li><a href="https://www.cnmumen.com/about/agreement.html">使用协议</a></li>
          |
          <li><a href="https://www.cnmumen.com/about/copyright.html">版权隐私</a></li>
          |
          <li><a href="https://www.cnmumen.com/sitemap/" target="_blank">网站地图</a></li>
          |
          <li><a href="https://www.cnmumen.com/ad/" target="_blank" rel="nofollow">广告服务</a></li>
          |
          <li><a href="https://www.cnmumen.com/guestbook/" target="_blank" rel="nofollow">网站留言</a></li>
          |
          <li><a href="https://www.cnmumen.com/feed/" target="_blank" rel="nofollow">RSS订阅</a></li>
          |
          <li><a href="javascript:SendReport();">违规举报</a></li>
        </ul> -->
        <br>
        <p>Copyright ©  傲因优选（www.aoyinyouxuan.com）2023  All Rights Reserved.</p>
        <p>电话：0579-87051467  在线QQ：3345521831  <a href="https://beian.miit.gov.cn/" target="_blank" class="beian">沪ICP备2022034676号-2</a></p>
        <p>免责声明：本站上会员自行发布的信息的真实性、准确性和合法性由发布会员负责，对此不承担任何保证责任.</p>
      </div>

      <div class="right">
        <p class="aoyin">Aoyin</p>
        <div class="img">
          <img src="@/assets/images/qrcode.jpg" alt="" />
        </div>
      </div>
    </div>
  </div>
  <!-- <div></div> -->
</template>

<script>
export default {
  name: "ayFoot",
  props: {
    navIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      navList: [
        // { name: "首页", url: "/index/home" },
        // { name: "特惠", url: "/index/discounts" },
        // { name: "品牌墙", url: "/index/brandWall" },
        // { name: "店铺", url: "/index/shop" },
        // { name: '关于我们', url: '/index/about' }
      ],
    };
  },
  methods: {
    changeIndex(index) {
      this.$emit("change", {
        index: index,
      });
    },
  },
};
</script>

<style lang="scss">
// .foot {
//   padding: $aoyin-padding-sm;

//   &__title {
//     font-size: 36px;
//     color: #b80d0d;
//     text-align: center;
//     padding: $aoyin-padding-sm;
//   }

//   ul {
//     margin: 0 auto;
//     display: flex;
//     justify-content: space-around;
//     gap: 20px;
//     padding: 24px;
//     box-sizing: border-box;
//     align-items: center;
//     list-style: none;
//     width: 500px;
//     height: 70px;
//     // border: 1px solid red;

//     li {
//       // border: 1px solid;
//       text-align: center;
//       cursor: pointer;

//       a {
//         text-decoration: none;
//         color: #000;
//         display: block;
//         width: 100%;
//         height: 100%;
//       }
//     }

//     .active {
//       a {
//         color: $aoyin-color-primary;
//       }
//     }
//   }

//   .breakTop {
//     cursor: pointer;
//     // border: 1px solid red;
//     padding: $aoyin-padding-sm;

//     &__button {
//       margin: 0 auto;
//       border: 1px solid black;
//       width: 195px;
//       height: 66px;
//       border-radius: 10px;
//       display: grid;
//       justify-content: center;
//       align-items: center;
//     }
//   }
// }

.foot {
  background: #f3f3f3;
  height: 200px;
  // border: 1px solid;
  margin-top: 100px;
  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // border: 1px solid;
    height: 100%;
    padding: 35px 0;
    .right {
      display: flex;
      // border: 1px solid;
      justify-content: space-between;
      align-items: center;
      gap: 80px;
      position: relative;
      height: 100%;
      .img {
        // border: 1px solid red;
        // width: 50%;
        img {
          // height: 50%;
          width: 100px;
        }
      }
      .aoyin {
        // border: 1px solid;
        font-size: 36px;
        font-weight: 500;
        color: #b80d0d;
        margin-bottom: 0;
        // align-items: center;
        // text-align: center;
        // padding: $aoyin-padding-sm;
      }
    }
    .left {
      height: 100%;
      // border: 1px solid;
      ul {
        list-style: none;
        display: flex;
        // border: 1px solid;
        color: #666666;
        gap: 10px;
        font-size: 18px;
        li {
          a {
            color: #666666;
            // &:hover {
            //   text-decoration: none;
            // }
          }
        }
      }
      p{
        margin-bottom: 0;
        font-size: 18px;
        color: #666666;
        
      }
      .beian{
        color: #666666;
      }
    }
  }
}
</style>