<template>
  <div class="index">

    <ay-nav :navIndex="navIndex" @change="(e) => { navIndex = e.index }" />
    
    <!-- <table  class="table table-striped"/> -->
    <router-view />

    <!-- <ay-foot :navIndex="navIndex" @change="(e) => { navIndex = e.index }" /> -->

      <!-- <div class="window"  :class="!closeWindow ? 'closeWindow' : ''">
          <div class="head">
            申请成为会员
            <i class="el-icon-close" @click="closeWindow = false"></i>
          </div>
          <div class="content">
              <div class="top">
                <el-input v-model="input" placeholder="邀请码"></el-input>
              </div>

               <div class="buttom">
                <button>成为会员</button>
               </div>
          </div>
      </div> -->
      
  </div>
</template>

<script>
import ayNav from "@/views/home/components/ayNav.vue";
// import ayFoot from "@/views/home/components/ayFoot.vue";
export default {
  name: "ayIndex",
  components: {
    ayNav
  },
  data() {
    return {
      navIndex: 0,
      closeWindow:true
    }
  },
  mounted() {

    console.log(this.$route.query)



    // var i = 0
    // this.navList.forEach(item => {
    //   if (item.url == this.$route.path) {
    //     this.navIndex = i
    //   }
    //   i = i + 1
    // })

  },
  methods: {
  }
}
</script>

<style lang="scss">
@import "@/assets/css/index.css";
.closeWindow{
  display: none;
}
.banner {
  // border: 1px solid;
  background: url("@/assets/images/bg.jpg") no-repeat;
  background-size: cover;
  background-position: center;
  height: 735px;
}
.window{
  // border: 1px solid;
  height: 130px;
  width: 250px;
   position:fixed;
   right:0;
   bottom:50px;
  z-index:99;
  // bottom: 50px;
  .head{
    display: flex;
    justify-content: space-between;
    background-color: #B80D0D;
    color: white;
    align-items: center;
    font-size: 15px;
    padding: 10px;
    .el-icon-close{
      cursor: pointer;
    }
  }
  .content{
    height: 100%;
    background-color: white;
    display: grid;
    align-content: space-between;
    .top{
      padding: 20px;
      .el-input{
        input{
          &:focus{
            border: 1px solid #B80D0D;
          }
        }
      }
    }
    .buttom{
      // border: 1px solid;
      display: flex;
      justify-content: flex-end;
      button{
        background-color: #B80D0D;
        color: white;
        font-size: 15px;
        border-radius: 5px;
        padding: 5px 10px;
      }
    }
  }
}
</style>