<template>
  <div>
    <div class="nav m" :class="type==0 ? 'block' : ''">
      <div class="logo">Aoyin</div>
      <ul class="button">
        <li v-for="(item, index) in navList" :key="index" :class="navIndex == index ? 'active' : ''"
          @click="changeIndex(index)">
          <router-link :to="item.url">{{ item.name }}</router-link>
        </li>
      </ul>


      <div class="search" v-if="type">
        <el-input size="mini" placeholder="请输入您要找的品牌名称" class="input-with-select">
          <el-button slot="append" icon="el-icon-search" class="button">搜索</el-button>
        </el-input>
        <div class="login" @click="login" v-if="!isLogin">登录</div>
       <div class="login"  v-else @click="userCenter">个人中心</div>

        <i class="el-icon-shopping-cart-2 cart" @click="toCartView"></i>
      </div>


    </div>

    
  </div>
</template>

<script>
import { getToken } from '@/utils/auth'
export default {
  name: "ayNav",
  components: {
  },
  data() {
    return {
      isLogin:getToken(),
      navList: [
        { name: '首页', url: '/index/home' },
        { name: '特惠', url: '/index/discounts' },
        { name: '品牌墙', url: '/index/brandWall' },
        { name: '店铺', url: '/index/shop' },
         { name: '关于我们', url: '/index/about' }
      ],
    }
  },
  props: {
    navIndex: {
      type: Number,
      default: -1
    },
    type:{
      type: Number,
      default: 1
    }
  },
  mounted() { },
  methods: {
    changeIndex(index) {
      this.$emit('change', {
        index: index
      })
    },
    login() {
      this.$router.push({ name: "Login" })
    },
    toCartView(){
      this.$router.push({ path: "/cart" })
    },
    userCenter(){
      this.$router.push({ name: "UserCenter" })
    }

  }
};
</script>

<style lang="scss">

.block{
  display: block !important;
}

.nav {
  // border: 1px solid;
  @include flex;
  margin: 0 auto;
  .login {
    cursor: pointer;
  }

  .logo {
    color: $aoyin-color-primary;
    font-size: 36px;
    font-weight: 600;
    text-align: center;
  }

  ul {
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    gap: 20px;
    padding: 24px;
    box-sizing: border-box;
    align-items: center;
    list-style: none;
    width: 800px;
    height: 70px;
    // border: 1px solid red;

    li {
      // border: 1px solid;
      text-align: center;
      cursor: pointer;

      a {
        text-decoration: none;
        color: #000;
        display: block;
        width: 100%;
        height: 100%;
      }
    }

    .active {
      a {
        color: $aoyin-color-primary;
      }
    }
  }

  .search{
    @include flex;
    // border: 1px solid;
    gap: 45px;
    // width: 300px;
    .el-input__inner{
      border: 0px;
      &:focus{
        border: 0px;
      }
    }
  }

  .input-with-select {
    border: 1px solid;
    border-radius: 24px;
    overflow: hidden;
    width: 300px;
  }

  .cart {
    font-size: 30px;
  }
}
</style>