<template>
  <div id="app">
    <router-view/>
    <!-- <ay-foot :navIndex="navIndex" @change="(e) => { navIndex = e.index }" /> -->
   <ay-foot/>
  </div>
</template>

<script>

import ayFoot from "@/views/home/components/ayFoot.vue";
export default {
    components: {
 ayFoot
  },
};
</script>

<style lang="scss">

#app {
  // width: 1440px;
  // margin: 0 auto;
  // border: 1px solid;
}

</style>
