import router from './router'
import { getToken } from '@/utils/auth'

router.beforeEach((to, from, next) => {
    /* 路由发生变化修改页面title */
    if (to.meta.title) {
      document.title = to.meta.title
    }
    if (to.meta.requireAuth) {
      if(getToken()){
        next()
      }else{
        next({ path: '/login' })
      }
    }
    next()
  })