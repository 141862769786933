// const API_BASE_URL = 'https://api.cnnmumen.com/';

// const API_BASE_URL = 'http://1.117.69.177:8080/';
// const API_BASE_URL = "http://127.0.0.1/";
// module.exports = {
//   AppointmentType: API_BASE_URL + 'installationMaintenanceCompany/getServicePrice',  //获取预约类型
//   AppointmentCom: API_BASE_URL + 'installationMaintenanceCompany/getCompany',  //获取预约安装公司
//   AppointmentByCom: API_BASE_URL + 'installationMaintenanceCompany/getCompanyById',  //按ID获取预约安装公司
//   AppointmentInfo: API_BASE_URL + 'installationMaintenanceCompany/getServiceById',  //获取预约信息

//   AppointmentPay: API_BASE_URL + 'payService/getWxUsedOrderPayData',  //预约定金
//   AppointmentOrder: API_BASE_URL + 'installationMaintenanceOrder/getOrders',  //获取预约订单

//   BrandList: API_BASE_URL + 'brand/getAllBrand',  //获取品牌列表
//   BrandDetail: API_BASE_URL + 'brand/getBrandById',  //获取品牌详情

//   CategoryList: API_BASE_URL + 'product/getCategoryArray', //获取分类列表

//   DiscountsTime: API_BASE_URL + 'miaosha/getMiaoshaActivityRecent', //获取抢购时间
//   DiscountsGoods: API_BASE_URL + 'miaosha/getMiaoshaActivityById', //获取抢购商品

//   GoodsList: API_BASE_URL + 'product/getByShowAreaId', //获取商品列表
//   GoodsByBrand: API_BASE_URL + 'product/getByBrand', //获取各品牌商品列表
//   GoodsByCategory: API_BASE_URL + 'product/getByCategory', //获取各分类商品
//   GoodDetail: API_BASE_URL + 'product/getByProductId',//获取商品详情

//   ImageSwiper: API_BASE_URL + 'picturePacket/getPicturePacketById',//获取海报图片

//   OrderSubmit: API_BASE_URL + 'payOrder/getWxUsedOrderPayData',//提交订单
//   OrderPay: API_BASE_URL + 'order/getWxOrderPayData',//订单支付
//   OrderReceive: API_BASE_URL + 'order/submitPicked',//订单确认收货

//   UserAddress: API_BASE_URL + 'userAddressInfo/selectAllByUserId',//获取用户地址
//   UserUpdateAddress: API_BASE_URL + 'userAddressInfo/submitUpdate',//更新用户地址
//   UserAddAddress: API_BASE_URL + 'userAddressInfo/submitAdd',//新增用户地址
//   UserDelAddress: API_BASE_URL + 'userAddressInfo/submitDelete',//删除用户地址
//   UserOrders: API_BASE_URL + 'order/selectAll',//获取用户全部订单
//   UserOrderDetail: API_BASE_URL + 'order/selectByBussinessId',//获取用户订单详情
//   UserLocation: API_BASE_URL + 'userLocation/updateLocation',//更新用户定位信息
//   UserLogin: API_BASE_URL + 'wxMiniAuth/addOrUpdateUserInfoNew',//用户登录
//   UserPhoneNumber: API_BASE_URL + 'userBaseInfo/updatePhoneNumber',//解密用户手机号
//   UserIp: 'https://whois.pconline.com.cn/ipJson.jsp?ip=null&json=true',//获取用户IP定位信息

//   LiveRooms: API_BASE_URL + 'WXLiveInfo/getLiveInfo'//获取直播房间信息
// };
module.exports = {
  GoodsList: '/mall/product/getByShowAreaId', //获取商品列表
  GoodDetail: '/mall/product/getByProductId',//获取商品详情
  GoodsByBrand: '/mall/product/getByBrand', //获取各品牌商品列表
  GoodsByCategory: '/mall/product/getByCategory', //获取各分类商品
  GoodsAll: '/mall/product/getAllProduct', //获取全部商品

  BrandDetail: '/mall/brand/getBrandById',  //获取品牌详情
  BrandList: '/mall/brand/getAllBrand',  //获取品牌列表
  
  

  UserLogin: '/mall/login/loginByPhonenum', //用户登录
  UserQRCodeLogin: '/mall/login/getWeiXinOpenParams', //用户扫码登录
  UserCart: '/mall/cart/getCart', //获取用户购物车
  UserAddCart: '/mall/cart/addProduct', //添加购物车
  UserDelCart: '/mall/cart/deleteProducts', //删除购物车
  UserUpCart: '/mall/cart/updateCart', //更新购物车
  UserAddress: '/mall/userAddressInfo/selectAllByUserId', //获取用户地址
  UserInfo: '/mall/userBaseInfo/getUserBaseInfoSelf', //获取用户个人信息
  UserOrder: '/mall/order/selectAll', //获取用户全部订单
  UserAddAddress: '/mall/userAddressInfo/submitAdd',//新增用户地址
  UserDelAddress: '/mall/userAddressInfo/submitDelete',//删除用户地址
  UserUpdateAddress: '/mall/userAddressInfo/submitUpdate',//更新用户地址

  OrderSubmit: '/mall/order/createOrder', //提交订单
  PayOrder: '/mall/order/getOrderPayData', //支付订单
  OrderDetail: '/mall/order/selectByOrderId',//获取订单详情

  NewsAll: '/mall/news/pageListAll', //获取新闻列表
  NewsInfo: '/mall/news/getInfo', //获取新闻详情


  // OrderSubmit: '/mall/orderPay/createOrderAndReturnOrderPayData', //提交订单

};
