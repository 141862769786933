import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '@/views/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/shop/buy/detail',
    name: 'GoodDetails',
    component: () => import('@/views/good/buy/detail/index.vue'),
  },
  {
    path: '/shop/buy/',
    component: () => import('@/views/good/buy/index.vue'),
    children: [
      {
        path: 'checkout',
        name: 'Checkout',
        component: () => import('@/views/good/buy/checkout/index.vue'),
        meta: { title: '填写订单信息', requireAuth: true },
      },
      {
        path: 'confirm',
        name: 'Confirm',
        component: () => import('@/views/good/buy/confirm/index.vue'),
        meta: { title: '支付订单', requireAuth: true },
      }
    ]
  },
  {
    path: '/index',
    name: 'Index',
    redirect: '/index/home',
    component: Index,
    children: [
      {
        path: 'home',
        component: () => import('@/views/home/HomeView.vue'),
        name: 'Home',
        meta: { title: '傲因优选-入户门商城' }
      },
      {
        path: 'discounts',
        name: 'Discounts',
        component: () => import('@/views/discounts/index.vue'),
        meta: { title: '傲因优选-入户门商城-特惠' }
      },
      {
        path: 'brandWall',
        name: 'BrandWall',
        component: () => import('@/views/brandWall/index.vue'),
        meta: { title: '傲因优选-入户门商城-品牌墙' }
      },
      {
        path: 'shop',
        name: 'Shop',
        component: () => import('@/views/shop/index.vue'),
        meta: { title: '傲因优选-入户门商城-店铺' }
      },
      {
        path: 'about',
        name: 'About',
        component: () => import('@/views/about/index.vue'),
        meta: { title: '傲因优选-关于我们' }
      },
      {
        path: 'new',
        component: () => import('@/views/about/news/index.vue'),
        name: 'New',
        meta: { title: '傲因优选-企业新闻' }
      }
    ]
  },
  {
    path: '/category/list',
    name: 'categoryList',
    component: () => import('@/views/category/list/index.vue'),
    meta: { title: '全部商品分类' },
  },
  {
    path: '/brandShop/list',
    name: 'brandShop',
    component: () => import('@/views/brandShop/list/index.vue'),
    meta: { title: '品牌店铺' },
  },
  {
    path: '/user',
    name: 'UserCenter',
    component: () => import('@/views/user/index.vue'),
    meta: { title: '个人中心', requireAuth: true },
  },
  {
    path: '/userCenter',
    redirect: '/userCenter/address',
    name: 'UserCenter2',
    component: () => import('@/views/user-center/index.vue'),
    meta: { title: '个人中心', requireAuth: true },
    children:[
      // {
      //   path: 'userInfo',
      //   name: 'UserInfo',
      //   component: () => import('@/views/user-center/view/user-info.vue'),
      // },
      {
        path: 'address',
        name: 'UserAddress',
        component: () => import('@/views/user-center/view/user-address.vue'),
      },
      {
        path: '*',
        redirect: '/userCenter/address',
      },
    ]
  },
  {
    path: '/user/orderList',
    name: 'OrderList',
    component: () => import('@/views/user/order-list/index.vue'),
    meta: { title: '我的订单', requireAuth: true },
  },
  {
    path: '/user/orderView',
    name: 'OrderView',
    component: () => import('@/views/user/order-view/index.vue'),
    meta: { title: '订单详情', requireAuth: true },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login/index.vue'),
    meta: { title: '登录' }
  },
  {
    path: '/cart',
    name: 'Cart',
    component: () => import('@/views/cart/index.vue'),
    meta: { title: '购物车', requireAuth: true }
  },

  {
    path: '*',
    redirect: '/index/home',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
